import * as React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import StyledBox from '../../components/containers/StyledBox';
import StringProcess from '../../components/design/StringProcess';

const _containers = {
    title: 'Artificial Intelligence Expertise',
    text: 'We build production **turn-key ready** models from end-to-end, strategy to implementation at scale. Connect with our team to understand how we can leverage **unique expertise** in %applied AI%.',
    content: [
        {
            title: 'Our Team',
            subtitle: 'Strategy',
            text: 'We apply our deep expertise, knowledge, and passion to solve complex industry challenges, designed to fit your operations.',
        },
        {
            title: 'Custom Models',
            subtitle: 'Data Sci',
            text: 'Draw on our expertise in data science to develop low-latency optimized inference models in areas such as FinTech, Industry 4.0, and Smart City.',
        },
        {
            title: 'Labeling',
            subtitle: 'Development',
            text: 'Outsource manual labeling tasks with a dedicated specialist team for vector space or text classification, let us manage complexity of data quality assurance.',
        },
        {
            title: 'Cloud to Edge',
            subtitle: 'Platforms',
            text: "Whether it's computer vision or text transformers like GPT-3, our goal is to delivery autonomous scale implementation of advanced neural networks.",
        },
        {
            title: 'Insights',
            subtitle: 'Management',
            text: "We're about creating predictive value and actionable insights for deleveraging risk metrics for business success, presentable as dashboards.",
        },
    ],
};

class AiSolutions extends React.Component {
    public render() {
        return (
            <Flex flexWrap={'wrap'} alignItems={'center'}>
                <Flex
                    flexWrap={'wrap'}
                    flexDirection={'row-reverse'}
                    alignItems={'right'}
                    w={'100%'}
                    textAlign={'right'}
                >
                    <Text
                        w={'100%'}
                        my={[3, 5]}
                        fontSize={['xl', '2xl']}
                        color={'crimson'}
                    >
                        {_containers.title}
                    </Text>
                    <Text
                        w={['100%', '50%']}
                        color={'white'}
                        lineHeight={1.5}
                        fontSize={'lg'}
                    >
                        <StringProcess>{_containers.text}</StringProcess>
                    </Text>
                </Flex>
                <Flex flexWrap={'wrap'} flexDirection={'row-reverse'}>
                    {_containers.content.map((x, k) => {
                        return (
                            <StyledBox
                                key={k}
                                w={['100%', '50%', '33%', '25%']}
                                p={[3, 5]}
                                mr={[3, 5, 7]}
                                my={[3, 5, 7]}
                            >
                                <Text
                                    sx={{
                                        color: 'crimson',
                                        fontSize: 'xs',
                                        fontWeight: 600,
                                        textTransform: 'uppercase',
                                        letterSpacing: '5px',
                                    }}
                                >
                                    {x.subtitle}
                                </Text>
                                <Text my={[2, 3]} fontSize={['xl', '2xl']}>
                                    {x.title}
                                </Text>
                                <Text my={[2, 3]}>
                                    <StringProcess>{x.text}</StringProcess>
                                </Text>
                            </StyledBox>
                        );
                    })}
                </Flex>
            </Flex>
        );
    }
}

export default AiSolutions;
