import * as React from 'react';
import { Flex, Box, HStack, Text } from '@chakra-ui/react';
import Typed from 'react-typed';

import theme from '../../theme/theme';

import Section from '../../components/Layout/Section';
import MainCoverContent from '../../static/content/MainCoverContent';
import CoverTyped from '../../static/content/CoverTyped';
import CallToAction from '../../components/cta/CallToAction';
import StringProcess from '../../components/design/StringProcess';

class CoverContent extends React.Component {
    public render() {
        return (
            <Section>
                <Flex flexWrap={'wrap'} my={[3, 5, 7, 9]}>
                    <Box
                        width={'100%'}
                        px={[3, 5, 7]}
                        py={[3, 5]}
                        textAlign={'left'}
                    >
                        <HStack
                            w={'max-content'}
                            sx={{
                                p: 1,
                                px: 3,
                                borderRadius: 'full',
                                border: '2px',
                                borderColor: 'crimson',
                                mb: 3,
                            }}
                        >
                            <Text
                                sx={{
                                    color: 'white',
                                    fontWeight: 600,
                                }}
                            >
                                {MainCoverContent.subtitle}
                            </Text>
                        </HStack>
                        <Box my={[5, 12]} />
                        <Box
                            fontSize={['xl', '2xl']}
                            fontWeight={300}
                            color={theme.colors.white}
                            sx={{
                                textTransform: 'uppercase',
                                letterSpacing: '20px',
                                lineHeight: 1,
                            }}
                        >
                            <Text textAlign={'left'}>
                                {MainCoverContent.title}
                            </Text>
                        </Box>
                        <Text
                            sx={{
                                color: 'crimson',
                                fontWeight: 500,
                                fontSize: ['6xl', '8xl', '10xl'],
                                fontFamily: theme.fonts.serif,
                                letterSpacing: 'tight',
                                lineHeight: 1,
                                ml: '-7px',
                            }}
                        >
                            <Typed
                                strings={CoverTyped}
                                typeSpeed={70}
                                backSpeed={70}
                                backDelay={2500}
                                loop={true}
                                smartBackspace={true}
                                style={{}}
                            />
                        </Text>
                        <Box my={[3, 5]} />
                        <Text
                            maxW={['100%', '75%', '50%']}
                            fontSize={['lg']}
                            fontWeight={500}
                            color={theme.colors.white}
                            lineHeight={1.5}
                            letterSpacing={'tight'}
                            py={[3, 5]}
                        >
                            <StringProcess>
                                {MainCoverContent.text}
                            </StringProcess>
                        </Text>
                        <Box py={[3, 5]}>
                            <CallToAction />
                        </Box>
                    </Box>
                    <Flex
                        w={['100%', '50%']}
                        h={['100%']}
                        px={[3, 5]}
                        alignSelf={'end'}
                    >
                        <Text
                            color={'black'}
                            fontSize={['5xl', '6xl', '8xl']}
                            fontWeight={500}
                            sx={{
                                letterSpacing: '-10px',
                                textAlign: 'right',
                                lineHeight: 1,
                                w: '100%',
                                h: '100%',
                            }}
                        ></Text>
                    </Flex>
                </Flex>
            </Section>
        );
    }
}

export default CoverContent;
