import { IContentProps } from '../../types/IContentProps';

const MainCoverContent: IContentProps = {
    id: '1',
    slug: 'content:main:cover',
    title: 'Scale Leaders',
    subtitle: 'Cloud Native Data + AI',
    text: 'Our unique team of **agile developers**, **data scientists**, and **business strategists** will help you realize the transformational potential of %AI% in your business.',
};

export default MainCoverContent;
