import * as React from 'react';
import {
    Flex,
    Box,
    Text,
    Image,
    Link as ExternalLink,
    HStack,
    Button,
} from '@chakra-ui/react';

import StringProcess from '../design/StringProcess';
import theme from '../../theme/theme';
import { graphql, StaticQuery } from 'gatsby';
import CallToAction from './CallToAction';
import ListItem from '../containers/ListItem';

const _content = {
    title: 'Explainable AI Insights',
    text: 'Learn how to empower your team with critical insights to deliver production-ready models from Cloud to Edge.',
    url: 'https://insights.quant.one/?ref=lpg_qo_insights_blk',
    container: [
        'Open + Free to Access Model Library',
        'Read by Innovators at Fortune 500 Companies',
        'Cloud & Digital Transformation Journey',
        'Leading Agile Development Teams',
        'Access Case Studies & Publications',
        'Growing base of >10K M.A.U.',
    ],
    subtitle: 'Open source models and data-driven publications available soon.',
};

const _query = graphql`
    query {
        imageSharp(fluid: { originalName: { eq: "quant.one.mockup.png" } }) {
            fluid(maxWidth: 1024) {
                srcWebp
            }
        }
    }
`;

class Insights extends React.Component {
    public render() {
        return (
            <StaticQuery
                query={_query}
                render={(data, key) => {
                    return (
                        <Flex
                            key={key}
                            flexWrap={'wrap'}
                            w={'100%'}
                            sx={{
                                py: [5, 7],
                                px: [5, 7, 9],
                                bg: theme.colors.brand.greys.black97,
                                borderRadius: 'xl',
                            }}
                        >
                            <Box w={['100%', '50%']}>
                                <Text
                                    fontSize={['2xl', '3xl']}
                                    fontFamily={theme.fonts.serif}
                                    color={'crimson'}
                                    my={3}
                                >
                                    {_content.title}
                                </Text>
                                <Text color={'white'} my={5}>
                                    <StringProcess>
                                        {_content.text}
                                    </StringProcess>
                                </Text>
                                <Box my={5}>
                                    {_content.container.map((x, k) => (
                                        <ListItem key={k}>{x}</ListItem>
                                    ))}
                                </Box>
                                <HStack>
                                    <ExternalLink href={_content.url} mr={2}>
                                        <Button
                                            colorScheme={'red'}
                                            borderRadius={'full'}
                                        >
                                            {'Explore Insights'}
                                        </Button>
                                    </ExternalLink>
                                    <CallToAction />
                                </HStack>
                                <Box my={5}>
                                    <Text fontSize={'sm'}>
                                        {_content.subtitle}
                                    </Text>
                                </Box>
                            </Box>
                            <Box w={['0%', '50%']}>
                                <Image
                                    src={data.imageSharp?.fluid?.srcWebp}
                                    alt={`${_content.text} | Quant ONE`}
                                />
                            </Box>
                        </Flex>
                    );
                }}
            />
        );
    }
}

export default Insights;
