import * as React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';

const Content = {
    title: 'Impact',
    subtitle: 'Our solutions by the numbers',
    containers: [
        {
            title: '$1.2B+',
            text: 'Executive Decisions Enriched with Data+AI',
        },
        {
            title: '20+',
            text: 'E2E Cloud Lift-and-Shift Migrations',
        },
        {
            title: '50+',
            text: 'Proof of Concept Models Architected',
        },
        {
            title: '16.5K+',
            text: 'Developers Empowered on Social',
        },
        {
            title: '3:2',
            text: 'Average ROI from Model Implementation',
        }, 
        {
            title: 'AWS',
            text: 'Managed Services Partner',
        },
        {
            title: 'Azure',
            text: 'Microsoft Cloud Solutions Partner',
        },
        // {
        //     title: 'GCP',
        //     text: 'Google Cloud Services Partner',
        // },
        // {
        //     title: 'Oracle',
        //     text: 'Cloud Solutions Certified & Partners',
        // },
    ],
};

class Impact extends React.Component {
    public render() {
        return (
            <Flex w={'100%'} flexWrap={'wrap'} mx={[3, 5]} my={[5, 7, 9]}>
                <Box width={['100%']} textAlign={'center'}>
                    <Text
                        sx={{
                            fontSize: 'lg',
                            fontWeight: 600,
                            color: 'white',
                            textTransform: 'uppercase',
                        }}
                    >
                        {Content.subtitle}
                    </Text>
                    <Text
                        sx={{
                            fontSize: ['4xl', '6xl'],
                            fontWeight: 100,
                            letterSpacing: '1em',
                            textTransform: 'uppercase',
                            color: 'crimson',
                        }}
                    >
                        {Content.title}
                    </Text>
                </Box>
                <Flex
                    flexWrap={'wrap'}
                    alignContent={'center'}
                    textAlign={'center'}
                    w={['100%']}
                >
                    {Content.containers.map((x, k) => {
                        return (
                            <Box
                                key={k}
                                sx={{
                                    w: ['50%', '33%', '25%'],
                                    maxW: '15em',
                                    my: [5, 7],
                                    mx: 'auto',
                                    px: [2, 3],
                                }}
                            >
                                <Text
                                    sx={{
                                        fontWeight: 100,
                                        fontSize: ['4xl', '5xl'],
                                        color: 'crimson',
                                    }}
                                >
                                    {x.title}
                                </Text>
                                <Text
                                    sx={{
                                        fontSize: ['lg'],
                                        color: 'white',
                                    }}
                                >
                                    {x.text}
                                </Text>
                            </Box>
                        );
                    })}
                </Flex>
            </Flex>
        );
    }
}

export default Impact;
