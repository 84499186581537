import React from 'react';
import { Flex, Box, Text, List, ListItem } from '@chakra-ui/react';

import Section from '../Layout/Section';
import OurStory from '../../content/containers/OurStory';
import ContactUs from '../cta/ContactUs';
import CloudSolutions from '../../content/containers/CloudSolutions';
import AiSolutions from '../../content/containers/AiSolutions';
import Impact from '../../content/containers/Impact';
import Advantage from '../../content/containers/Advantage';
import StringProcess from '../design/StringProcess';
import Insights from '../cta/Insights';
import ContactWrapper from '../cta/ContactWrapper';

class Home extends React.Component {
    public render() {
        return (
            <Section>
                <Text
                    m={[3]}
                    sx={{
                        textAlign: 'center',
                        color: 'gray.500',
                        fontSize: 'xs',
                        fontWeight: '500',
                        letterSpacing: '10px',
                        textTransform: 'uppercase',
                    }}
                >
                    {'Scroll Down'}
                </Text>
                <Flex flexWrap={'wrap'} w={['100%']} p={[3, 5]}>
                    <OurStory />
                    <Text
                        sx={{
                            w: '100%',
                            fontSize: ['xl', '2xl'],
                            fontWeight: 100,
                            letterSpacing: '1em',
                            textTransform: 'uppercase',
                            textAlign: 'left',
                            color: 'gray.800',
                        }}
                    >
                        <StringProcess>
                            {'Expertise // Advantage'}
                        </StringProcess>
                    </Text>
                    <CloudSolutions />
                    <AiSolutions />
                </Flex>
                <Flex flexWrap={'wrap'} w={'100%'} p={[3, 5]}>
                    <Impact />
                    <Insights />
                </Flex>
                <Flex flexWrap={'wrap'} w={'100%'} py={[3, 5]}>
                    <ContactWrapper />
                </Flex>
            </Section>
        );
    }
}

export default Home;
