import React from 'react';

import AppContainer from '../components/Layout/AppContainer';
import Layout from '../components/Layout/Layout';
import Home from '../components/pages/Home';
import Metadata from '../components/SEO/Metadata';
import SEO from '../components/SEO/SEO';
import CoverContent from '../content/containers/CoverContent';

import './index.css';

/**
 * Declare default resource path adapter for {AppContainer} passthrough
 * @returns {JSX.Element}
 * @constructor
 */
class Index extends React.Component {
    public render() {
        return (
            <AppContainer>
                <SEO
                    title={Metadata.title}
                    description={Metadata.description}
                    lang={Metadata.lang}
                />
                <Layout cover={<CoverContent />}>
                    <Home />
                </Layout>
            </AppContainer>
        );
    }
}

export default Index;
