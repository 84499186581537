/**
 * @constant string array of typed animation defaults
 */
const CoverTyped = [
    'Explainable AI',
    'Deep Learning',
    'Serverless',
    'Computer Vision',
    'Neural Nets',
    'Black Box AI',
];

export default CoverTyped;
