import * as React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import StyledBox from '../../components/containers/StyledBox';
import StringProcess from '../../components/design/StringProcess';

const _containers = {
    title: 'Cloud Solutions',
    text: "Wherever you are on the %cloud transformation journey%, we'd like to propel you forward. Working alongside your team, we'll define your **strategic options**, **develop**, and deploy **scale-ready** architecture to achieve measurable **business outcomes**.",
    content: [
        {
            title: 'Strategy',
            subtitle: 'Journey',
            text: "We understand revenue and customer experience drive decisions. We're about creating predictive value with industry solutions.",
        },
        {
            title: 'Cloud Native',
            subtitle: 'Architecture',
            text: "Infrastructure that's resilient and replicable, go from planning to shipping solutions that drive quantitative results quicker.",
        },
        {
            title: 'Multi-Cloud',
            subtitle: 'Platforms',
            text: "Whether you're on AWS, Azure, or Google Cloud - we're here to accelerate your journey as a trusted Cloud Partner.",
        },
        {
            title: 'Data Driven',
            subtitle: 'DevOps',
            text: 'Telemetry is the heartbeat of our design. We track and measure for low-latency experiences to optimize cloud adoption roadmap.',
        },
        {
            title: 'Cost Optimization',
            subtitle: 'Financial',
            text: 'Solutions architected for predicable cloud spend with pricing plans to enable scalable runway your CFO will love to support.',
        },
    ],
};

class CloudSolutions extends React.Component {
    public render() {
        return (
            <Flex flexWrap={'wrap'} alignItems={'center'}>
                <Box w={'100%'}>
                    <Text
                        my={[3, 5]}
                        fontSize={['xl', '2xl']}
                        color={'crimson'}
                    >
                        {_containers.title}
                    </Text>
                    <Text
                        w={['100%', '50%']}
                        color={'white'}
                        lineHeight={1.5}
                        fontSize={'lg'}
                    >
                        <StringProcess>{_containers.text}</StringProcess>
                    </Text>
                </Box>
                <Flex flexWrap={'wrap'} alignItems={'flex-start'}>
                    {_containers.content.map((x, k) => {
                        return (
                            <StyledBox
                                key={k}
                                w={['100%', '50%', '33%', '25%']}
                                p={[3, 5]}
                                mr={[3, 5, 7]}
                                my={[3, 5, 7]}
                            >
                                <Text
                                    sx={{
                                        color: 'crimson',
                                        fontSize: 'xs',
                                        fontWeight: 600,
                                        textTransform: 'uppercase',
                                        letterSpacing: '5px',
                                    }}
                                >
                                    {x.subtitle}
                                </Text>
                                <Text my={[2, 3]} fontSize={['xl', '2xl']}>
                                    {x.title}
                                </Text>
                                <Text my={[2, 3]}>
                                    <StringProcess>{x.text}</StringProcess>
                                </Text>
                            </StyledBox>
                        );
                    })}
                </Flex>
            </Flex>
        );
    }
}

export default CloudSolutions;
