import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

const StyledBox = styled(Box)`
  background: #040404;
  color: white;
  borderRadius: xl;

  &:hover {
    transform: scale(1.05);
    transition: ease 150ms;
  }
`;

export default StyledBox;
